import { Icon, Tooltip } from "antd";

import React from "react";
import WrapChartComponent from "../WrapChartComponent";

class WrapPreemptTSPDetailChart extends WrapChartComponent {
  handleShowHideLegend = (legend) => {
    const {
      dataProps: { hiddenLegend },
      updateFilterData,
    } = this.props;

    let newHiddenLegend = [...hiddenLegend];

    if (newHiddenLegend?.includes(legend)) {
      newHiddenLegend = newHiddenLegend.filter((item) => item !== legend);
    } else {
      newHiddenLegend.push(legend);
    }
    updateFilterData("hiddenLegend", newHiddenLegend);
  };

  render() {
    const { t, isCompareMode, isPrintMode, children, dataProps } = this.props;

    return (
      <div
        style={{ height: this.props.height, position: "relative" }}
        className="mb-16 wrap-chart-preempt-detail"
      >
        {!isCompareMode && (
          <div className="chart-info-wrap flex justify-space-beetwen">
            <div className="font-bold">
              {t("analysis.tsp") + " " + dataProps.data.phase}
            </div>
          </div>
        )}
        {this.state.isZomming && (
          <Tooltip placement="top" title={t("analysis.zoom_explaination")}>
            <Icon
              onClick={this.clearZoomingChart}
              className="chart-zoom-mark"
              type="reload"
            />
          </Tooltip>
        )}
        {!isCompareMode ? (
          <div className="chart-container">
            <div className="chart-detail-container">
              {" "}
              {React.cloneElement(children, {
                onChartZoom: this.handleChartZoom,
                ref: this.chartData,
              })}
            </div>
          </div>
        ) : (
          <div
            className={
              isPrintMode ? "chart-container compare-chart" : "chart-container"
            }
          >
            <div className="chart-detail-container">
              {" "}
              {React.cloneElement(children, {
                onChartZoom: this.handleChartZoom,
                ref: this.chartData,
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WrapPreemptTSPDetailChart;
